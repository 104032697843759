// Here you can add other styles

input[type='text'],
input[type='number'],
textarea {
  font-size: 16px;
}

.ReactTable .rt-table {
    height: 30rem;
    -webkit-overflow-scrolling: touch;
    overflow: unset !important;
}